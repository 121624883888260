import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../App/store";

const initialState: boolean = false;

const loginModalSlice = createSlice({
  name: "LoginModal",
  initialState,
  reducers: {
    showLoginModal: (state, action: PayloadAction<boolean>) => {
      state = action.payload;
      return state;
    },
    hideLoginModal: (state, action: PayloadAction<boolean>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { showLoginModal, hideLoginModal } = loginModalSlice.actions;

export const showLoginMod = (state: RootState) => state;

export default loginModalSlice.reducer;
