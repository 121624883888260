import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import betslipModalSlice from "../Components/Slices/betslipModalSlice";
import depositModalSlice from "../Components/Slices/depositModalSlice";
import gameSlice from "../Components/Slices/gameSlice";
import showMenuSlice from "../Components/Slices/hideMenuModalSlice";
import jackpotSlice from "../Components/Slices/jackpotSlice";
import loginModalSlice from "../Components/Slices/loginModalSlice";
import userSlice from "../Components/Slices/userSlice";

const rootReducer = combineReducers({
  games: gameSlice,
  showLoginModal: loginModalSlice,
  showDepositModal: depositModalSlice,
  user: userSlice,
  showBetslipModal: betslipModalSlice,
  jackpot: jackpotSlice,
  menu: showMenuSlice,
});

const persistConfig = {
  key: "kwikbet-jackpot",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
