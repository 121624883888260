import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../App/store";

const initialState: boolean = false;

const depositModalSlice = createSlice({
  name: "depositModal",
  initialState,
  reducers: {
    showDepositModal: (state, action: PayloadAction<boolean>) => {
      state = action.payload;
      return state;
    },
    hideDepositModal: (state, action: PayloadAction<boolean>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { showDepositModal, hideDepositModal } = depositModalSlice.actions;

export const showDepostMod = (state: RootState) => state;

export default depositModalSlice.reducer;
