function DownloadGames() {
  return (
    <div className="w-full h-full">
      <iframe
        src="https://www.kwikbet.co.ke/sportsbook/#/"
        title="Download Games"
        className="w-full h-full"
      ></iframe>
    </div>
  );
}

export default DownloadGames;
