import { FC } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import Logo from "../../../assets/images/KwikBet Logo FInal.png";
import { hideBetslipModal } from "../../Slices/betslipModalSlice";
import { showDepositModal } from "../../Slices/depositModalSlice";
import { showLoginModal } from "../../Slices/loginModalSlice";
import { deleteUser } from "../../Slices/userSlice";
import Quicklinks from "../Quicklinks";

const MainSection: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  return (
    <div className="flex justify-evenly w-screen  items-center bg-gradFromFree py-2">
      <div className=" cursor-pointer flex-1" onClick={() => (window.location.href = "https://www.kwikbet.co.ke/#/")}>
        <img
          src={Logo}
          alt="Logo.png"
          className="object-contain lg:object-cover h-16 w-30 md:h-16 lg:h-14 lg:w-48 md:w-28"
        />
      </div>
      <div className="flex-1 hidden md:flex">
        <Quicklinks />
      </div>
      <div className="flex flex-1 justify-end gap-4 mr-4">
        <div>
          <button
            className="bg-secondary py-1 font-primary text-xs rounded-sm px-2"
            onClick={() => {
              dispatch(showLoginModal(false));
              dispatch(hideBetslipModal());
              dispatch(showDepositModal(true));
            }}
          >
            Deposit
          </button>
        </div>
        <div>
          {!user.isLoggedIn ? (
            <button
              className="bg-white font-primary text-xs rounded-sm px-2 py-1"
              onClick={() => {
                dispatch(showDepositModal(false));
                dispatch(hideBetslipModal());
                dispatch(showLoginModal(true));
              }}
            >
              Login
            </button>
          ) : (
            <button
              className="bg-white font-primary text-xs rounded-sm px-2 py-1"
              onClick={() => {
                dispatch(deleteUser());
                Swal.fire({
                  title: "Logging Out",
                  text: "Sad to see you Leave. Rudi Tena",
                  icon: "success",
                  confirmButtonText: "Exit",
                  timer: 1000,
                });
              }}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainSection;
