import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import ActionProvider from "./ActionProvider";
import config from "./config";
import MessageParser from "./MessageParser";
import "./styles.css";
const Index = () => {
  return (
    <div className="lg:w-1/3 w-full h-full overflow-hidden lg:shadow-lg">
      <Chatbot
        config={config}
        actionProvider={ActionProvider}
        messageParser={MessageParser}
        className="w-full h-full"
      />
    </div>
  );
};

export default Index;
