import { useEffect } from "react";
import OneSignal from "react-onesignal";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

const queryClient = new QueryClient();

function App(): JSX.Element {
  useEffect(() => {
    OneSignal.init({ appId: "26832b83-7ac9-4996-8f19-76e3e7b3f8f2" });
  }, []);

  return (
    <div className="">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes basename="/" />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
