import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../App/store";
import { User } from "../../models/userModel";

const initialState: User = {
  phoneNumber: "",
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<string>) => {
      state.isLoggedIn = true;
      state.phoneNumber = action.payload;
      return state;
    },
    deleteUser: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { addUser, deleteUser } = userSlice.actions;

export const user = (state: RootState) => state;

export default userSlice.reducer;
