function ProblemOptions(props) {
  const data = [
    {
      text: "Deposit",
      handler: props.actionProvider.handleDeposit,
      id: 1,
    },
    {
      text: "Withdraw",
      handler: props.actionProvider.handleWithdraw,
      id: 2,
    },
    {
      text: "Registration Code",
      handler: props.actionProvider.handleRegistrationCode,
      id: 3,
    },
    {
      text: "Reset Password",
      handler: props.actionProvider.handleResetPassword,
      id: 4,
    },
    {
      text: "Pending Bets",
      handler: props.actionProvider.handlePendingBets,
      id: 5,
    },
    {
      text: "Account",
      handler: props.actionProvider.handleAccount,
      id: 6,
    },
    {
      text: "Jackpot",
      handler: props.actionProvider.handleJackpot,
      id: 7,
    },
    // {
    //   text: "General Information",
    //   handler: props.actionProvider.handleInformation,
    //   id: 8,
    // },
  ];
  const optionsList = data.map((option) => (
    <button key={option.id} onClick={option.handler} className="py-2 px-4 border border-gradFromFree rounded-lg">
      {option.text}
    </button>
  ));
  return (
    <div className="my-2">
      <div className="flex gap-4 flex-wrap my-2">{optionsList}</div>
    </div>
  );
}
export default ProblemOptions;
