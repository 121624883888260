// MessageParser starter code
class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }
  //takes in a mesaage and logs it in the screen
  parse(message) {
    const lowercase = message.toLowerCase();
    if (lowercase.includes("hello") || lowercase.includes("hi")) {
      this.actionProvider.messageHandler();
    } else if (lowercase.includes("deposit")) {
      this.actionProvider.handleDeposit();
    } else if (lowercase.includes("withdrawal")) {
      this.actionProvider.handleWithdraw();
    } else if (lowercase.includes("password")) {
      this.actionProvider.handleSqlQuiz();
    } else if (lowercase.includes("exit")) {
      this.actionProvider.handleContinue();
    } else if (lowercase.includes("jackpot")) {
      this.actionProvider.handleJackpot();
    } else if (lowercase.includes("registrationCode")) {
      this.actionProvider.handleRegistrationCode();
    } else if (lowercase.includes("passwordReset")) {
      this.actionProvider.handlePassword();
    } else if (lowercase.includes("account")) {
      this.actionProvider.handleAccount();
    } else {
      this.actionProvider.handleUnknown();
    }
  }
}
export default MessageParser;
