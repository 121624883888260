import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import { Slip, UserSlip } from "../../../models/jackpotModels";
import { hideBetslipModal } from "../../Slices/betslipModalSlice";
import { showDepositModal } from "../../Slices/depositModalSlice";
import { deleteGame, deleteGames } from "../../Slices/gameSlice";
import { showLoginModal } from "../../Slices/loginModalSlice";
import Loader from "../Loader";

const Betslip: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();
  const betSlip = useAppSelector((state) => state.games);
  const user = useAppSelector((state) => state.user);
  const jackpot = useAppSelector((state) => state.jackpot);
  const [loading, setLoading] = useState<boolean>(false);

  const userSlips = async (): Promise<UserSlip[]> => {
    let data = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_JACKPOT_API_URL}user/slips`,
      data: { phoneNumber: user.phoneNumber },
    });

    return data.data;
  };

  const mutations = useMutation(userSlips, {
    onSuccess: (data) => {
      queryClient.setQueryData("myBets", data);
    },
  });

  const handlePlaceJackpot = () => {
    setLoading(true);
    const slips: Slip[] = [];

    betSlip.map((event) => {
      let slip: Slip = {
        OutcomeShortCode: event.choice,
        eventCode: event.eventCode,
      };
      slips.push(slip);
      return null;
    });

    let slipsEvent = {
      linkID: "kwikbet",
      phoneNumber: user.phoneNumber,
      jackpotID: jackpot.id,
      slip: slips,
    };

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_JACKPOT_API_URL}jackpot/placeBet/web`,
      data: slipsEvent,
    })
      .then((res) => {
        mutations.mutate();
        history.push("/my-bets");
        dispatch(deleteGames());
        dispatch(hideBetslipModal());
      })
      .catch((err) => console.log(err.response))
      .finally(() => setLoading(false));
  };

  return (
    <AnimatePresence>
      <motion.div className="z-40 w-full bg-gray-100 shadow rounded overflow-y-scroll">
        <div className="flex w-full  flex-col border-0 rounded-sm justify-center items-">
          <div className="flex font-primary font-bold py-1 px-2 cursor-pointer bg-primary">
            <div className="w-full text-center">
              <h1 className="font-primary font-normal text-white">Betslip</h1>
            </div>
          </div>
          <div className=" flex-1 w-full items-center flex flex-col mt-0 ">
            {betSlip.length < 1 ? (
              <h1 className="font-primary font-semibold text-xs py-4 text-center">
                Please Pick your choices to place the bet
              </h1>
            ) : (
              <div className="w-full h- flex flex-col items-center overflow-y-scroll">
                {betSlip.map((game, index) => (
                  <div key={index} className="px-4 bg-white shadow-lg flex flex-col mb-2 w-11/12 justify-center">
                    <div className="text-left flex w-full justify-between items-center">
                      <div>
                        <p className="font-bold font-primary text-xs py-1">{`${game.homeTeam} - ${game.awayTeam}`}</p>
                      </div>
                      <div
                        className="font-bold font-primary text-xs px-2 py-2 cursor-pointer"
                        onClick={() => dispatch(deleteGame(index))}
                      >
                        x
                      </div>
                    </div>

                    <div className="lg:-mt-1">
                      <p className="font-light font-primary text-xxs lg:text-xs pb-1">Your Pick : {game.choice}</p>
                    </div>
                  </div>
                ))}
                {/* <div className="w-10/12">
                  <div className="flex justify-between w-full text-xs font-primary font-semibold py-1">
                    <div>Possible Win</div>
                    <div>Ksh. 500,000</div>
                  </div>
                  <div className="flex justify-between w-full text-xs font-primary font-semibold py-1">
                    <div>Tax</div>
                    <div>Ksh. {0.075 * 500000}</div>
                  </div>
                  <div className="flex justify-between w-full text-xs font-primary font-semibold py-1">
                    <div>Payout</div>
                    <div>Ksh. {500000 - 0.075 * 500000}</div>
                  </div>
                </div> */}
                {!loading ? (
                  <div className="flex w-10/12 gap-6 py-2">
                    <div className="flex-1 cursor-pointer">
                      <input
                        onClick={() => {
                          dispatch(deleteGames());
                          dispatch(hideBetslipModal());
                        }}
                        type="button"
                        value="Remove all"
                        className="bg-primary py-2 rounded text-white font-primary font-bold text-xs w-full cursor-pointer"
                      />
                    </div>
                    {user.isLoggedIn ? (
                      <div className="flex-1 cursor-pointer">
                        <input
                          type="button"
                          value="Place Bet"
                          disabled={betSlip.length === 9 ? false : true}
                          className={
                            betSlip.length === 9
                              ? `bg-secondary py-2  rounded text-white font-primary font-bold text-xs w-full cursor-pointer`
                              : `bg-gray-200 py-2  rounded text-white font-primary font-bold text-xs w-full cursor-pointer`
                          }
                          onClick={() => handlePlaceJackpot()}
                        />
                      </div>
                    ) : (
                      <div className="flex-1 cursor-pointer">
                        <input
                          type="button"
                          value="Login to Place Bet"
                          className={`bg-secondary py-2  rounded text-white font-primary font-bold text-xs w-full cursor-pointer`}
                          onClick={() => {
                            dispatch(showDepositModal(false));
                            dispatch(hideBetslipModal());
                            dispatch(showLoginModal(true));
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Betslip;
