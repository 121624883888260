import axios from "axios";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalLinks } from "../../models/headerModels";

const Quicklinks: FC = (): JSX.Element => {
  const [links] = useState<GlobalLinks[]>([
    // { name: "Sports", url: "/download-games" },
    { name: "Sports", url: "https://www.kwikbet.co.ke/sportsbook/#/" },
    { name: "Jackpot", url: "/games" },
    { name: "App", url: "/app" },
    { name: "Kandege", url: "https://www.kwikbet.co.ke/aviator/" },
    { name: "Casino", url: "https://www.kwikbet.co.ke/casino" },
    { name: "VIP", url: "/vip" },
    { name: "Chat", url: "/chat" },
    { name: "Download", url: "/downloads" },
  ]);

  const handleDownload = () => {
    axios({
      url: "https://betsms.solami.co.ke/carbone/download-games",
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "All Games.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="w-full text-black py-4 overflow-x-scroll flex items-center px-2">
      <button
        onClick={handleDownload}
        className="text-xs lg:text-sm font-primary text-center justify-center md:text-white"
      >
        Print
      </button>

      <div className="lg:w-1/2 w-full flex justify-start lg:gap-4">
        {links.map((quicklink, i) => (
          <div
            key={i}
            className="text-xs lg:text-sm font-primary leading-none flex-1 text-center text-white md:py-1  rounded min-w-1/4"
          >
            {quicklink.name === "Sports" ||
            quicklink.name === "Kandege" ||
            quicklink.name === "Casino" ? (
              <a
                href={quicklink.url}
                className="text-center text-black md:text-white"
              >
                <span
                  className="w-full text-center"
                  style={{ wordBreak: "break-word" }}
                >
                  {quicklink.name}
                </span>
              </a>
            ) : (
              <NavLink
                to={quicklink.url}
                className={
                  quicklink.name === "Chat"
                    ? "text-black md:text-white flex flex-1 text-center justify-center lg:hidden"
                    : "text-black md:text-white flex flex-1 text-center justify-center"
                }
              >
                {quicklink.name}
              </NavLink>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Quicklinks;
