// Config starter code
import { createChatBotMessage } from "react-chatbot-kit";
import Account from "./widgets/Account";
import Deposits from "./widgets/Deposits";
import Jackpot from "./widgets/Jackpot";
import PasswordReset from "./widgets/Password";
import PendingBets from "./widgets/PendingBets";
import ProblemOptions from "./widgets/ProblemOptions";
import RegistrationCodes from "./widgets/RegistrationCode";
import Withdrawal from "./widgets/Withdrawal";

const config = {
  botName: "Kwikbot",
  initialMessages: [
    createChatBotMessage(`Hi and Jambo! Im here to assist you. How may I help you?`),
    createChatBotMessage("What would you like to know about?  Click below", {
      withAvatar: true,
      delay: 500,
      widget: "options",
    }),
  ],

  customStyles: {
    botMessageBox: {
      backgroundColor: "#28334AFF",
    },
    chatButton: {
      backgroundColor: "#567572ff",
    },
  },
  customComponents: {
    header: () => (
      <div
        style={{
          backgroundColor: "#600662",
          padding: "5px",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          fontSize: "0.85rem",
          paddingTop: "12.5px",
          paddingBottom: "12.5px",
          paddingRight: "12.5px",
          paddingLeft: "12.5px",
          fontWeight: "700",
          alignItems: "center",
          color: "white",
        }}
      >
        Conversation with Kwikbot
      </div>
    ),
  },

  state: {},
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <ProblemOptions {...props} />,
    },
    {
      widgetName: "deposit",
      widgetFunc: (props) => <Deposits {...props} />,
    },
    {
      widgetName: "withdraw",
      widgetFunc: (props) => <Withdrawal {...props} />,
    },
    {
      widgetName: "jackpot",
      widgetFunc: (props) => <Jackpot {...props} />,
    },
    {
      widgetName: "pendingBets",
      widgetFunc: (props) => <PendingBets {...props} />,
    },
    {
      widgetName: "registrationCodes",
      widgetFunc: (props) => <RegistrationCodes {...props} />,
    },
    {
      widgetName: "passwordReset",
      widgetFunc: (props) => <PasswordReset {...props} />,
    },
    {
      widgetName: "account",
      widgetFunc: (props) => <Account {...props} />,
    },
  ],
};

export default config;
