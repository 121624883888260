import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../App/store";
import { SlipEvent } from "../../models/jackpotModels";

const initialState: SlipEvent[] = [];

export const gamesSlice = createSlice({
  name: "gameSlice",
  initialState,
  reducers: {
    addGames: (state: SlipEvent[], action: PayloadAction<SlipEvent>) => {
      state = [...state, action.payload];
      return state;
    },
    deleteGame: (state: SlipEvent[], action: PayloadAction<number>) => {
      state.splice(action.payload, 1);
      return state;
    },
    modifyGames: (state: SlipEvent[], action: PayloadAction<SlipEvent[]>) => {
      state = action.payload;
      return state;
    },
    deleteGames: (state: SlipEvent[]) => {
      state = initialState;
      return state;
    },
  },
});

export const { addGames, deleteGames, modifyGames, deleteGame } =
  gamesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGames = (state: RootState) => state.games;

export default gamesSlice.reducer;
