import { motion } from "framer-motion";
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../App/hooks";
import BannerImage from "../../assets/images/Banner.png";
import Chat from "../Body/Chat2/index";
import { Header } from "../Global/Header";
import Betslip from "../Global/Main/Betslip";
import BetslipModal from "../Global/Main/BetslipModal";
import DepositModal from "../Global/Main/DepositModal";
import LoginModal from "../Global/Main/LoginModal";
import SubMenu from "../Global/SubMenu";

const Base: FC = ({ children }): JSX.Element => {
  const loginModal = useAppSelector((state) => state.showLoginModal);
  const depositModal = useAppSelector((state) => state.showDepositModal);
  const betslipModal = useAppSelector((state) => state.showBetslipModal);
  const [showLiveChat, setShowLiveChat] = useState<boolean>(false);

  const { pathname } = useLocation();

  const fullPageLinks = ["/", "/previous-results", "/my-bets", "/games"];

  return (
    <div className="flex flex-col h-screen w-screen relative">
      <div className="">
        <Header />
      </div>

      <div
        className="flex-1 w-screen h-full my-0 md:my-8 lg:my-0  lg:flex lg:w-full lg:justify-center lg:gap-0 lg:overflow-y-auto relative"
        id="children"
      >
        <div className="hidden lg:flex lg:my-4">
          {fullPageLinks.includes(pathname) && <SubMenu />}
        </div>
        {children}
        {fullPageLinks.includes(pathname) && (
          <div className="hidden lg:flex lg:flex-col lg:my-4  lg:overflow-y-scroll">
            <div>
              <img
                src={BannerImage}
                alt="banner.png"
                className="object-cover h-24"
              />
            </div>
            <Betslip />
          </div>
        )}
      </div>
      <div
        className=" absolute bottom-20 left-8 hidden lg:flex hover:cursor-pointer bg-primary py-2 px-4 rounded"
        onClick={() => setShowLiveChat(!showLiveChat)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          className="fill-secondary"
        >
          <path
            id="Icon_material-chat_bubble_outline"
            data-name="Icon material-chat_bubble_outline"
            d="M30,3H6A3.009,3.009,0,0,0,3,6V33l6-6H30a3.009,3.009,0,0,0,3-3V6A3.009,3.009,0,0,0,30,3Zm0,21H9L6,27V6H30Z"
            transform="translate(-3 -3)"
          />
        </svg>
        {!showLiveChat && (
          <motion.p
            initial={{ x: -10 }}
            animate={{ x: 0 }}
            className="font-primary text-white mx-2 font-bold"
          >
            Chat with us
          </motion.p>
        )}
      </div>
      <motion.div className="hidden lg:flex w-screen absolute bottom-20 left-20 z-50">
        {showLiveChat && (
          <motion.div
            animate={{ opacity: 1, right: -"200%" }}
            initial={{ opacity: 0 }}
            className="w-1/2"
          >
            <Chat />
          </motion.div>
        )}
      </motion.div>
      {loginModal && <LoginModal />}
      {depositModal && <DepositModal />}
      {betslipModal && <BetslipModal />}
    </div>
  );
};

export default Base;
