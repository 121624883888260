import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = false;

const betslipModalSlice = createSlice({
  name: "betslipSlice",
  initialState,
  reducers: {
    showBetslipModal: (state) => {
      state = true;
      return state;
    },
    hideBetslipModal: (state) => {
      state = false;
      return state;
    },
  },
});

export const { showBetslipModal, hideBetslipModal } = betslipModalSlice.actions;

export default betslipModalSlice.reducer;
