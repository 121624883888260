import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { GlobalLinks } from "../../models/headerModels";
import { showBetslipModal } from "../Slices/betslipModalSlice";

const SubMenu: FC = (): JSX.Element => {
  const betslip = useAppSelector((state) => state.games);

  const dispatch = useAppDispatch();

  const { show } = useAppSelector((state) => state.menu);

  const links: GlobalLinks[] = [
    { name: "Games", url: "/games" },
    { name: "My Bets", url: "/my-bets" },
    { name: "Previous JP", url: "/previous-results" },
    // { name: "How To Play", url: "/how-to" },
  ];

  return (
    <div className={show ? " bg-primary w-full lg:bg-transparent " : " bg-primary lg:bg-none w-full fixed top-0 z-10"}>
      <div className="flex w-full lg:flex-col justify-between lg:justify-end gap-4 lg:gap-10 px-2 lg:px-4 py-2 overflow-x-">
        {links.map((link) => (
          <div className="" key={link.url}>
            <NavLink
              to={link.url}
              exact
              activeClassName="font-bold border-b-2 border-white pb-1 md:w-full lg:bg-secondary lg:border-none"
              className="font-primary text-sm md:text-base text-white lg:text-black lg:font-bold lg:px-3 lg:py-2 lg:rounded"
            >
              {link.name}
            </NavLink>
          </div>
        ))}
        <div className="relative lg:hidden cursor-pointer w-16" onClick={() => dispatch(showBetslipModal())}>
          <span className="font-primary text-sm md:text font-bold text-white">Betslip</span>
          <span className="absolute text-white text-xxs bg-red-500 h-3 w-3 rounded-full top-0 right--2 text-center">
            {betslip.length}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
