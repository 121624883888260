import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import publicIp from "public-ip";
import React, { FC, useState } from "react";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../../App/hooks";
import { UserLoginReq } from "../../../models/userModel";
import { hideLoginModal, showLoginModal } from "../../Slices/loginModalSlice";
import { addUser } from "../../Slices/userSlice";
import Loader from "../Loader";

const LoginModal: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [failedLogin, setFailedLogin] = useState<boolean>(false);

  const modal = {
    animate: {
      opacity: 1,
      x: "-50%",
      y: "40%",
    },
    initial: {
      opacity: 0,
      x: "-50%",
      y: "0%",
    },
    exit: {
      y: -100,
      opacity: 0,
    },
  };

  const transition = {
    ease: "easeInOut",
  };
  const handleLoginButton = async () => {
    setLoading(true);
    let user: UserLoginReq = {
      login: phoneNumber,
      password,
      ipAddress: await publicIp.v4(),
    };

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_JACKPOT_API_URL}user/auth`,
      data: user,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(addUser(phoneNumber));
          dispatch(showLoginModal(false));
          Swal.fire({
            title: "Login",
            text: "Login Successfull",
            icon: "success",
            color: "#F69E1F",
            timer: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      })
      .catch((err) => setFailedLogin(true))
      .finally(() => setLoading(false));
  };

  return (
    <AnimatePresence>
      <motion.div
        variants={modal}
        animate="animate"
        initial="initial"
        exit="exit"
        transition={transition}
        className=" absolute  z-20 h-1/2 w-10/12 lg:w-1/4 left-1/2 bg-gray-50 shadow-lg rounded"
      >
        <div className="flex h-full w-full flex-col border-0 rounded-sm justify-center items-">
          <div className="flex font-primary font-bold py-1 px-2 cursor-pointer bg-primary">
            <div className="w-11/12 text-center">
              <h1 className="font-primary font-normal text-white">Login</h1>
            </div>
            <div onClick={() => dispatch(hideLoginModal(false))}>
              <h1 className="px-2 font-primary font-bold text-white">x</h1>
            </div>
          </div>
          <div className=" flex-1 h-full w-full flex items-center justify-center flex-col ">
            <h1 className="font-primary font-bold">Welcome Back</h1>
            {failedLogin && <h3 className="font-primary font-bold text-xs">Invalid phone number or password</h3>}
            <input
              type="text"
              name=""
              id=""
              placeholder="07xx-xxx-xxx"
              className="py-3 px-2 text-xs placeholder-white-500 my-2 rounded-md shadow-md font-primary placeholder:text-xs focus:border-secondary focus:outline-none focus:border-2"
              onChange={(e) => {
                setFailedLogin(false);
                setPhoneNumber(e.target.value);
              }}
            />
            <input
              type="password"
              name=""
              id=""
              placeholder="Enter Password"
              className="py-3 px-2 text-xs placeholder-white-500 my-2 rounded-md shadow-md font-primary placeholder:text-xs focus:border-secondary focus:outline-none focus:border-2"
              onChange={(e) => setPassword(e.target.value)}
            />
            {!loading ? (
              <input
                type="button"
                value="Login"
                onClick={handleLoginButton}
                className="py-2 px-4 mt-2 cursor-pointer rounded font-primary font-bold bg-secondary"
              />
            ) : (
              <Loader />
            )}
            <div className="text-center w-full py-3">
              <span className="text-xs font-bold font-primary text-faint">&#169;</span>
              <span className="text-xs font-primary font-thin">Kwikbet 2022</span>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoginModal;
