// ActionProvider starter code
class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  handleDeposit = () => {
    const message = this.createChatBotMessage(
      "Please Fill in The Form Below and Our Customer Care agents will get back to you",
      {
        widget: "deposit",
        withAvatar: true,
      }
    );
    this.setChatbotMessage(message);
  };
  handleWithdraw = () => {
    const message = this.createChatBotMessage(
      "Please share with us more details below and we will assist you instantly. Asante!",
      {
        widget: "withdraw",
      }
    );
    this.setChatbotMessage(message);
  };
  handleJackpot = () => {
    const message = this.createChatBotMessage(
      "Please share with us more details below and we will assist you instantly. Asante!",
      {
        widget: "jackpot",
      }
    );
    this.setChatbotMessage(message);
  };
  handlePendingBets = () => {
    const message = this.createChatBotMessage(
      "Please share with us more details below and we will assist you instantly. Asante!",
      {
        widget: "pendingBets",
      }
    );
    this.setChatbotMessage(message);
  };
  handleResetPassword = () => {
    const message = this.createChatBotMessage(
      "Please share with us more details below and we will assist you instantly. Asante!",
      {
        widget: "passwordReset",
      }
    );
    this.setChatbotMessage(message);
  };
  handleAccount = () => {
    const message = this.createChatBotMessage(
      "Please share with us more details below and we will assist you instantly. Asante!",
      {
        widget: "account",
      }
    );
    this.setChatbotMessage(message);
  };
  handleContinue = () => {
    const message = this.createChatBotMessage("Choose A problem you are facing below", {
      widget: "options",
    });
    this.setChatbotMessage(message);
  };
  handleRegistrationCode = () => {
    const message = this.createChatBotMessage(
      "Please share with us more details below and we will assist you instantly. Asante!",
      {
        widget: "registrationCodes",
      }
    );
    this.setChatbotMessage(message);
  };
  handleUnknown = () => {
    const message = this.createChatBotMessage("Please type continue to try again");
    this.setChatbotMessage(message);
  };
  handleGreeting = () => {
    const message = this.createChatBotMessage("Thanks for trying QuizBot! We hope you had a great learning experience");
    this.setChatbotMessage(message);
  };
  handleSuccess = (msg) => {
    const message = this.createChatBotMessage(msg);
    this.setChatbotMessage(message);
  };

  messageHandler = (msg) => {
    const message = this.createChatBotMessage("Hello,what do you want to learn", {
      widget: "options",
    });
    this.setChatbotMessage(message);
  };
  setChatbotMessage = (messages) => {
    if (Array.isArray(messages)) {
      this.setState((state) => ({
        ...state,
        messages: [...state.messages, ...messages],
      }));
    } else {
      this.setState((state) => ({
        ...state,
        messages: [...state.messages, messages],
      }));
    }
  };
  clearChatBotMessage = (message) => {
    this.setState((state) => ({
      ...state,
      messages: [...state.messages, message],
    }));
  };
}
export default ActionProvider;
