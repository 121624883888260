import { FC, useEffect, useState } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const ServiceWrapper: FC = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(false);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return showReload ? <div onClick={reloadPage}>{showReload && <h1>A new version is available!</h1>}</div> : <div></div>;
};

export default ServiceWrapper;
