import axios from "axios";
import { ApiData } from "./apiModels";

export const createDepositIssue = async (data: ApiData) => {
  console.log(data);
  let res = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_CHATBOT_API_URL}api/v1/issues`,
    data,
  });

  return res;
};
