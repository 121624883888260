import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../App/hooks";
import { STKPush } from "../../../models/stkPushModels";
import {
  hideDepositModal,
  showDepositModal,
} from "../../Slices/depositModalSlice";
import { showLoginModal } from "../../Slices/loginModalSlice";
import Loader from "../Loader";

const DepositModal: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const [amount, setAmount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const modal = {
    animate: {
      opacity: 1,
      x: "-50%",
      y: "40%",
    },
    initial: {
      opacity: 0,
      x: "-50%",
      y: "0%",
    },
    exit: {
      y: -100,
      opacity: 0,
    },
  };

  const transition = {
    ease: "easeInOut",
  };
  const handleSTKPUSH = () => {
    setLoading(true);
    const stkData: STKPush = {
      //@ts-ignore
      Amount: amount,
      Phone: user.phoneNumber.replace("+", "").replace(/\s+/g, ""),
    };

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_JACKPOT_API_URL}deposit`,
      data: stkData,
    })
      .then((res) => {
        Swal.fire({
          title: "Deposit",
          text: "Deposit Request Sent",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Deposit",
          text: "Deposit Request Not Successfull. Please deposit direct to paybill 290028",
          icon: "info",
          confirmButtonColor: "#F69E1F",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <AnimatePresence>
      <motion.div
        variants={modal}
        animate="animate"
        initial="initial"
        exit="exit"
        transition={transition}
        className=" absolute  z-20 h-1/2 w-10/12 lg:w-1/4 left-1/2 bg-gray-50 shadow-lg lg:shadow-2xl rounded"
      >
        <div className="flex h-full w-full  flex-col border-0 rounded-sm justify-center items-">
          <div className="flex font-primary font-bold py-1 px-2 cursor-pointer bg-primary">
            <div className="w-11/12 text-center">
              <h1 className="font-primary font-normal text-white">Deposit</h1>
            </div>
            <div onClick={() => dispatch(hideDepositModal(false))}>
              <h1 className="px-2 font-primary font-bold text-white">x</h1>
            </div>
          </div>
          <div className=" flex-1 w-full flex items-center justify-center flex-col ">
            <h6 className="font-primary font-light text-xxs lg:text-xs lg:px-1">
              Please ensure that your phone is unlocked to complete your
              transaction
            </h6>
            {user.isLoggedIn ? (
              <input
                type="text"
                name=""
                id=""
                onChange={(e) => setAmount(parseInt(e.target.value))}
                placeholder="Enter Amount"
                className="w-10/12 py-3 px-2 text-xs placeholder-white-500 my-2 rounded-md shadow-md font-primary placeholder:text-xs focus:border-secondary focus:outline-none focus:border-2"
              />
            ) : (
              <div className="font-primary font-bold text-center text-xs lg:w-6/12">
                <p>Please login to Deposit</p>
                <button
                  onClick={() => {
                    dispatch(showDepositModal(false));
                    dispatch(showLoginModal(true));
                  }}
                  className="px-6 py-2 lg:bg-primary mt-4 rounded font-primary font-bold text-white hover:bg-secondary bg-secondary"
                >
                  Login
                </button>
              </div>
            )}
            {user.isLoggedIn && !loading ? (
              <input
                type="button"
                value="Deposit"
                className="py-2 px-4 mt-2 cursor-pointer rounded font-primary font-bold bg-secondary"
                onClick={() => handleSTKPUSH()}
              />
            ) : loading ? (
              <Loader />
            ) : null}
          </div>

          <div className="flex-1 w-full">
            <div className="text-center w-full font-primary">OR</div>
            <div className="flex w-full justify-center items-center">
              <div className="text-xxs lg:text-xs font-primary">
                1. Go to M-PESA Menu on your phone <br />
                2. Select LIPA NA MPESA <br />
                3. Select PAYBILL <br />
                4. Enter Business Number 290028 <br />
                5. Enter Account Number as YOUR NUMBER <br />
                6. Enter the Amount <br />
                7.Enter your MPESA PIN <br />
                8. You shall receive an SMS Confirmation
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DepositModal;
