import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { showMenuReducer } from "../Slices/hideMenuModalSlice";
import MainSection from "./Main/MainSection";
import Quicklinks from "./Quicklinks";
import SubMenu from "./SubMenu";

export const Header: FC = (): JSX.Element => {
  const { show } = useAppSelector((state) => state.menu);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const fullPageLinks = ["/", "/previous-results", "/my-bets", "/games"];

  const listenToScroll = () => {
    let heightToHideFrom = 50;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      show && // to limit setting state only the first time
        dispatch(showMenuReducer(false));
    } else {
      dispatch(showMenuReducer(true));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll, true);

    return () => window.removeEventListener("scroll", listenToScroll, true);
  });
  return (
    <div className="flex flex-col">
      {show && <MainSection />}
      <div className="flex-1 w-full flex md:hidden">
        {show && <Quicklinks />}
      </div>
      {/* {show && fullPageLinks.includes(pathname) && <Banner />} */}
      {fullPageLinks.includes(pathname) && (
        <div className="flex md:hidden">
          <SubMenu />
        </div>
      )}
    </div>
  );
};
