import { FC, lazy, Suspense } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import DownloadGames from "./Components/Body/DownloadGames";
import Base from "./Components/Layout/Base";

const Games = lazy(() => import("./Components/Body/Jackpot/Games"));
const PrevResults = lazy(() => import("./Components/Body/Result/PrevResults"));
const MyBets = lazy(() => import("./Components/Body/Jackpot/MyBets"));
const HowToPlay = lazy(() => import("./Components/Body/Jackpot/HowToPlay"));
const Downlaod = lazy(() => import("./Components/Body/Download"));
const VIP = lazy(() => import("./Components/Body/VIP/Vip"));
const Freebet = lazy(() => import("./Components/Body/Freebet/index"));
//@ts-ignore
const Chat = lazy(() => import("./Components/Body/Chat2/index"));

interface IPROPS extends RouteComponentProps<any> {
  basename: string;
}

const Routes: FC<IPROPS> = ({ basename }): JSX.Element => {
  return (
    <Base>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/how-to" component={HowToPlay} />
          <Route path="/previous-results" component={PrevResults} />
          <Route path="/my-bets" component={MyBets} />
          <Route exact path="/" component={Games} />
          <Route exact path="/games" component={Games} />
          <Route exact path="/app" component={Downlaod} />
          <Route exact path="/vip" component={VIP} />
          <Route exact path="/free-bet" component={Freebet} />
          <Route exact path="/chat" component={Chat} />
          <Route exact path="/download-games" component={DownloadGames} />
        </Switch>
      </Suspense>
    </Base>
  );
};

export default withRouter(Routes);
