import { useState } from "react";
import { useAppSelector } from "../../../../App/hooks";
import Loader from "../../../Global/Loader";
import { createDepositIssue } from "../Api/api";

const Withdrawal = (props) => {
  const { isLoggedIn, phoneNumber } = useAppSelector((state) => state.user);

  const [phone, setPhone] = useState(phoneNumber);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let res = await createDepositIssue({ phone: phoneNumber, code: "01", description });
      props.actionProvider.handleSuccess(res.data);
      props.actionProvider.handleContinue();
    } catch (error) {
      props.actionProvider.handleSuccess("An Error Occurred");
    }
    setLoading(false);
  };

  return (
    <div className="w-full flex justify-center bg-white">
      <div className="w-3/4 flex flex-col justify-center items-center">
        <h1 className="text-center">
          Please share with us more details below and we will assist you instantly. Asante!
        </h1>
        <form action="" className="w-full flex flex-col gap-4 my-4">
          {isLoggedIn ? (
            <div className="flex flex-col gap-2">
              <label htmlFor="Phone Number">Phone Number</label>
              <input
                type="text"
                value={phone}
                className="border border-black px-2 py-4"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <label htmlFor="Phone Number">Phone Number</label>
              <input type="text" className="border border-black px-2 py-4" onChange={(e) => setPhone(e.target.value)} />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <label htmlFor="Phone Number">
              Description <span className="font-light text-xs italic opacity-50 mx-2">Optional</span>
            </label>
            <textarea
              type="text"
              className="border border-black px-2 py-4"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {!loading ? (
            <input
              type="button"
              className="w-full py-4 my-4 bg-secondary cursor-pointer text-white font-bold"
              onClick={() => handleSubmit()}
              value="Report"
            />
          ) : (
            <Loader />
          )}
        </form>
      </div>
    </div>
  );
};

export default Withdrawal;
