import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Jackpot } from "../../models/jackpotModels";

const initialState: Jackpot = {
  games: [],
  id: "",
  jackpotType: "",
  name: "",
  amount: "",
  market: "",
  stake: "",
  expiresAt: "",
  createdAt: "",
  updatedAt: "",
};

const jackpotSlice = createSlice({
  name: "jackpotSlice",
  initialState,
  reducers: {
    addJackpot: (state, action: PayloadAction<Jackpot>) => {
      state = action.payload;
      return state;
    },
    removeJackpot: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { addJackpot, removeJackpot } = jackpotSlice.actions;
export default jackpotSlice.reducer;
